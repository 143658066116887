.container {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    color: black;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.info {
    width: 100%;
}

.name {
    font-size: 2em;
    background: linear-gradient(rgb(0, 135, 172), rgb(0, 110, 143));
    width: 100%;
    text-align: center;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    color: white;
    font-weight: 500;
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.info button {
    padding: 8px 50px;
    font-size: 1.2em;
    width: fit-content;
    align-self: center;
    margin-top: 3em;
    background: black;
    color: white;
    border-radius: 13px;
    border: none;
    box-shadow: 0 3px 10px rgba(0,0,0,0.3);
}

.header {
    justify-self: flex-start;
    background: white;
    width: 100%;
    height: 45px;
    z-index: 9999;
    color: black;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    box-shadow: 0 3px 10px rgba(0,0,0,0.3);
}

.header button {
    width: fit-content;
    display: flex;
    height: fit-content;
    margin: auto;
    margin-left: 5px;
    font-size: 1.2em;
    color: rgb(80,150,255);
    background: transparent;
    border: none;
    align-items: center;
}

.spacer {
    background: rgb(245,245,245);
    width: 100%;
    height: 45%;
    margin-top: auto;
    display: flex;
    border-top: 1px solid rgba(0,0,0,0.2);
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    gap: 0.4em;
}
.divider {
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin: 10px 0;
}

.header button svg {
    font-size: 1.23em;
    margin-top: -1px;
}

.header span {
    font-size: 1.3em;
    text-align: center;
    margin: auto;
}

.info {
    display: flex;
    flex-direction: column;
}