.container {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 30px 15px 0 15px;
    color: rgb(90, 20, 60);
    background: url("/public/background.jpeg");
    background-size: cover;
}

.container h1 {
    font-size: 2.4em;
    margin: 0;
}

.container h3 {
    font-weight: normal;
    font-size: 0.9em;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;
}

.device {
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    background: rgba(0,0,0,0.6);
    color: rgb(230,230,230);
    min-width: 150px;
    min-height: 150px;
    cursor: pointer;
}

.device, .dataDevice {
    display: flex;
    gap: 1em;
    width: 100%;
    font-size: 1.1em;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px) saturate(2);
}

.dataDevice {
    justify-content: flex-start;
    align-items: center;
    background: rgba(255,255,255,0.7);
    color: black;
    min-height: 150px;
    min-width: 350px;
}

.dataDeviceLink {
    color: black;
    text-decoration: none;
    grid-column: 1/3;
}

.deviceWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

.holder {
    display: flex;
    flex-direction: column;
}

.holder :last-child {
    font-size: 0.8em;
    letter-spacing: 1px;
    color: gray;
}

.link {
    color: black;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

.deviceContainer>span {
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9em;
    margin: 30px 0 10px 0;
}

.tempHigh, .tempLow {
    font-size: 2.4em;
}

.tempHigh {
    color: #ff7300;
}

.tempLow {
    color: #0075ff;
}

.off {
}

.off .holder :last-child {
    color: darkgray;
}

.on {
    background: rgba(255,255,255,0.7);
    color: black;
}

.bigTemp {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.bigTemp > * {
    grid-column: 1;
    grid-row: 1;
}

.bigTemp > span {
    display: block;
    margin: auto;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 3em;
    text-align: center;
}

.giantDataView {
    align-self: stretch;
    flex-grow: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.giantDataView > span {
    font-size: 1.3em;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    font-family: "Montserrat";
}

.giantDataView > p {
    margin: 0;
    color: gray;
    font-size: 0.9em;
}

@media only screen and (max-width: 600px) {
    .device {
        aspect-ratio: 4/3;
        min-width: unset;
        min-height: unset;
    }

    .dataDevice {
        min-height: 100px;
        min-width: unset;
    }
    .deviceWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.3em;
    }
}